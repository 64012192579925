import React, { Component } from "react"
import Layout from "../components/layout-component"
import { graphql } from "gatsby"
import StyledHero from "../components/StyledHero"
import { injectIntl } from "gatsby-plugin-intl"
import ParaEllaComponent from "../components/ParaElla/para-ella-component"
import { SearchBox } from "../components/ParaElla/search-box-component"
import Title from "../components/title-component"
import styles from "../css/para-ella.module.css"
class ParaElla extends Component {
  constructor() {
    super()
    this.state = {
      products: [],
      searchField: "",
    }
  }
  //cuando se carga la pagina
  componentDidMount() {
    this.setState({ products: this.props.data.para_ella.edges })
  }

  handleChange = text => {
    this.setState({ searchField: text.target.value })
  }

  render() {
    const { intl } = this.props
    const { products, searchField } = this.state
    const filteredProducts = products.filter(({ node }) =>
      node.nombre.toLowerCase().includes(searchField.toLowerCase())
    )
    return (
      <Layout>
        <StyledHero
          img={this.props.data.paraEllaHero.childImageSharp.fluid}
        ></StyledHero>
        <div className={styles.wrapper}>
          <Title
            title={`${intl.formatMessage({ id: "paraEllaTitle" })}`}
            subtitle={`${intl.formatMessage({ id: "paraElllaSubtitle" })}`}
          />
          <SearchBox
            placeholder={`${intl.formatMessage({ id: "buscarSombreros" })}`}
            handleChange={this.handleChange}
          />
        </div>
        <ParaEllaComponent data={filteredProducts} />
      </Layout>
    )
  }
}

export const query = graphql`
  query($locale: String) {
    paraEllaHero: file(relativePath: { eq: "portada2.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    para_ella: allContentfulBravohatsData(
      filter: { node_locale: { eq: $locale }, hombre: { eq: false } }
    ) {
      edges {
        node {
          nombre
          slug
          hombre
          mujer
          contentful_id
          descripcion {
            descripcion
          }
          etiquetas {
            etiqueta
          }
          precios {
            calidad
            precio
          }
          imagenes {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
export default injectIntl(ParaElla)
